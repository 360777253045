import React, { useState, useEffect, useContext } from "react";

import { ClientContext } from "../../components/context/ClientContext"

// import { Navheader } from "../Homepage/Header"
import linkllogo from "../../assets/imges/logo-icon.svg"
import whatsAppImg from "../../assets/imges/WhatsApp.png"

import "./Shortendurl.css"

export function Shortendurl() {
    const { customData } = useContext(ClientContext)
    const [shortedUrlData, setShortedUrlData] = useState("")

    async function copyUrl() {
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText("content");
        } else {
            // unsecuredCopyToClipboard("content");
        }
        await navigator.clipboard.writeText("shortedUrlData.short_url")
    }

    function getUrlDetailed() {
        if (customData.shortUrlData) {
            console.log(customData.shortUrlData);
            setShortedUrlData(customData.shortUrlData)
        }
    }

    useEffect(() => {
        getUrlDetailed()
    }, [])

    return (
        <>
            {/* <Navheader /> */}
            <div>
                <div className="shortendContainer">
                    <h3 style={{
                        "textAlign": "center",
                        "color": "orange",
                        "fontWeight": "bold"
                    }}>Your Shortend URL</h3>
                    <div className="underLineLog">
                        <div style={{
                            "border": "1px solid",
                            "width": "20%",
                            "height": "0",
                            "borderColor": "orange",
                            "marginTop": "14px",
                            "position": "relative"
                        }}></div>
                        <img className="shortendlinkllogo" src={linkllogo} alt="notFound" />
                        <div style={{
                            "border": "1px solid",
                            "width": "20%",
                            "height": "0",
                            "borderColor": "orange",
                            "marginTop": "15px",
                            "position": "relative"
                        }}></div>

                    </div>
                    <p>Copy the shortend Link and Share it message text,whatsapp,website and other locations</p>
                    <div className="input-group mb-3" style={{ "width": "80%" }}>
                        <input type="text" className="form-control" style={{ "fontWeight": "bold", "color": "black" }} value={shortedUrlData.short_url ? shortedUrlData.short_url : ""} disabled={true} placeholder="Recipient's username" />
                        <button type="button" className="btn btn-warning btn-sm" onClick={copyUrl} id="copyShortLink">Copy Short Link</button>
                    </div>
                    <a href={shortedUrlData.short_url} target="_blank">{shortedUrlData.short_url}</a>
                    <p><span>Long URL : </span><label>{customData.defaultUrl}</label></p>
                    <div style={{ "textAlign": "center" }}>
                        <img className="qrCodeLogo" src={shortedUrlData.qr_code ? `data:image/png;base64,${shortedUrlData.qr_code}` : null} alt="notFound" />
                        <p style={{ "marginTop": "1rem" }}>
                            Scan the QR Code to get the URL
                        </p>
                    </div>
                    <div className="shareUrl" >
                        <h3 className="shareHeader"> Share Shortend URL  : </h3>
                        <img className="whatsAppImg" src={whatsAppImg} alt="whatsAppImg" />

                    </div>
                </div>
            </div>

        </>
    )
}

