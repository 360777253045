import { Homepage } from "../pages/Homepage/Homepage";
import { Shortendurl } from "../pages/shortendurl/Shortendurl";
import { Notfound } from "../pages/UrlAuthentication/Notfound.jsx";
import { Login } from "../pages/UrlAuthentication/Login.jsx";
import { Useradvanceform } from "../pages/UrlAuthentication/Useradvanceform/Useradvanceform.jsx";
import { Shortlink } from "../pages/shortendurl/shortLinks/Shortlink.jsx";
import { Unshortendurl } from "../pages/shortendurl/UnshortendUrls/Unshortendurl";
import { Layout } from "../Admin/Layout";
import { Linkexpire } from "../pages/UrlAuthentication/Linkexpire.jsx";
import {MaliciousReport} from "../pages/shortendurl/MaliciousReport.jsx"

const routesComponents = [
  {
    path: "/shortLink",
    compnents: <Shortlink />,
    key: "Shortlink",
  },
  {
    path: "/home",
    compnents: <Homepage />,
    key: "Homepage",
  },
  {
    path: "/404Notfound",
    compnents: <Notfound />,
    key: "Notfound",
  },
  {
    path: "/Shortendurl",
    compnents: <Shortendurl />,
    key: "Shortendurl",
  },
  {
    path: "/Useradvance",
    compnents: <Useradvanceform />,
    key: "Useradvanceform",
  },
  {
    path: "/Unshortendurl",
    compnents: <Unshortendurl />,
    key: "Unshortendurl",
  },
  {
    path: "/Linkexpire",
    compnents: <Linkexpire />,
    key: "LinkEpire",
  },
  {
    path: "/login",
    compnents: <Login />,
    key: "Login",
  },
  {
    path: "/",
    compnents: <Layout />,
    key: "Layout",
  },
  {
    path: "/maliciousReport",
    compnents: <MaliciousReport />,
    key: "MaliciousReport",
  },

  
];

export default routesComponents;
