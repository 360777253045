import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { ClientContext } from "../../components/context/ClientContext"
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { userRegistration, getLoginCaptcha, submitUserLogin, getValidateCaptcha, checkLocalUserSession } from "../../utils/api/userSessionApi";
import "./Login.css"
import logo from "../../assets/imges/logo.svg"
import linkllogo from "../../assets/imges/logo-icon.svg"
import refreshLogo from "../../assets/imges/refreshLogo.svg"
import { Navigate } from "react-router-dom";


export function Login(props) {
    const navigate = useNavigate()
    const { customData } = useContext(ClientContext)


    const [loginCaptcha, setLoginCaptcha] = useState({})
    const [userName, setUserName] = useState('kundan_aarogya_setu')
    const [password, setPassword] = useState('8c6976e5b5410415bde908bd4dee15dfb167a9c873fc4bb8a81f6f2ab448a918')
    const [captcha, setCaptcha] = useState('')
    const [inputMissing, setInputMissing] = useState('')
    const [isLogin, setIsLogin] = useState(true)

    const [registerUserName, setRegisterUserName] = useState('')
    const [userPassword, setUserPassword] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [landline, setLandline] = useState('')
    const [fullName, setfullName] = useState('')
    const [departmentName, setDepartmentName] = useState('')
    const [applicationName, setApplicationName] = useState('')
    const [applicationDomain, setapplicationDomain] = useState('')


    async function getLogInCaptcha() {
        if (checkLocalUserSession()) {
            navigate('/')
        }

        console.log(customData);
        if (customData.setHeaderFooter) {
            const setHeaderFooter = customData.setHeaderFooter
            setHeaderFooter(true)
        }
        const response = await getLoginCaptcha()
        if (response.status === 200) {
            setLoginCaptcha(response.data)
        }
    }

    async function fillCaptcha(e) {
        const filledCaptcha = e.target.value
        if (filledCaptcha.length > 6) {
            setCaptcha(captcha)
            return
        } else {
            setCaptcha(filledCaptcha)
        }
    }

    async function submitLogInCredential() {
        if (userName.trim().length === 0) {
            setInputMissing("Enter Your User Name")
            return
        }
        if (password.trim().length === 0) {
            setInputMissing("Enter Your User Password")
            return
        }
        if (captcha.trim().length === 0) {
            setInputMissing("Enter Captcha")
            return
        }
        const logInCredential = {
            username: userName,
            password: password

        }
        const logInCaptchaObj = {
            captcha_id: loginCaptcha.captcha_id,
            captcha_answer: captcha
        }
        const resp = await getValidateCaptcha(logInCaptchaObj)

        if (resp.data && resp.status === 200) {
            if (resp.data.status) {
                const response = await submitUserLogin(logInCredential)
                if (response.hasOwnProperty("data")) {
                    localStorage.setItem("authToken", response.data.auth_token);
                    // window.location.href = "/";
                    window.location.replace("/")
                }
            }
            else {
                getLogInCaptcha()
                setCaptcha('')
                setInputMissing("Entered Captcha Is Invailid")
            }
        }
        else {
            // SetInvailidCatcha("Entered Captcha Is Invailid")
        }
    }

    async function submit() {
        const userData = {
            username: registerUserName,
            password: userPassword,
            contact_email: email,
            contact_mobile: mobile,
            contact_landline: landline,
            contact_person: fullName,
            department_name: departmentName,
            application_name: applicationName,
            application_domain: applicationDomain
        }
        console.log(userData);
        const response = await userRegistration(userData)
        console.log("response=======>>>>>", response);
        if (response.hasOwnProperty("data")) {
            setIsLogin(true)
        }
    }

    function checkPassword(e) {
        const val = e.target.value
        console.log(val.match(userPassword));
    }

    useEffect(() => {
        getLogInCaptcha()
        console.log("Login Called useEffect");
    }, [])
    return (
        <>
            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-12">
                            <div className="wrap d-md-flex">
                                <div className="img-1">

                                    <img className="logo-login" src={logo} alt="" />
                                    <div className="login-title">
                                        <h3>Welcome to URL.in !</h3>
                                    </div>
                                </div>

                                {isLogin ? <div className="login-wrap">
                                    <div className="form-logo">
                                        <img className="linkllogo" src={linkllogo} alt="notFound" />
                                    </div>
                                    <div className="d-flex"><h3 className="login-form-title">Login</h3></div>
                                    <div className="signin-form">
                                        <form className="px-5">

                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="position-relative mt-4">
                                                        <input type="text" className="outside" onChange={(e) => setUserName(e.target.value)} value={userName} />
                                                        <span className="floating-label-outside">Username</span>
                                                        <i className="fa fa-user-o input-icon-outside"></i>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="position-relative mt-4">
                                                        <input type="password" className="outside" onChange={(e) => setPassword(e.target.value)} value={password} />
                                                        <span className="floating-label-outside">Password</span>
                                                        <i className="fa fa-key input-icon-outside"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="forget-password"> <p>Forget your password?</p></div>
                                            <div classsname="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 pl-0 pr-0">
                                                    <div className="form-group d-flex">
                                                        <div className="captchaLogoGroup">
                                                            <img className="catchLogo" src={loginCaptcha.captcha_image_base64 ? `data:image/png;base64,${loginCaptcha.captcha_image_base64}` : null} alt="notFound" />
                                                            <img className="reloadLogo" onClick={getLogInCaptcha} src={refreshLogo} alt="notFound" />
                                                        </div>
                                                        <InputGroup className="mb-3 ml-3">
                                                            <Form.Control type="text" onChange={fillCaptcha} value={captcha} placeholder="Enter Captcha" />
                                                        </InputGroup>
                                                    </div>
                                                    <div className="form-group d-flex">
                                                        <label className="invailidCaptcha"> {inputMissing}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="button" className="btn btn-block" onClick={submitLogInCredential} id="loginButton">Sign in</button>
                                            </div>
                                            <div className="signup"> <p onClick={() => isLogin ? setIsLogin(false) : null}>Don't Have an Account?<span className="font-weight-600 ml-2">SignUp</span></p></div>
                                        </form>
                                    </div>
                                </div>
                                    :
                                    <div className="login-wrap p-4 p-md-0">
                                        <div className="form-logo">
                                            <img className="linkllogo" src={linkllogo} alt="notFound" />
                                        </div>
                                        <div className="d-flex">
                                            <h3 className="login-form-title">Create Account</h3>
                                        </div>
                                        <div className="signin-form">
                                            <form>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="position-relative mt-4">
                                                            <input type="text" className="outside" onChange={(e) => setfullName(e.target.value)} value={fullName} />
                                                            <span className="floating-label-outside">Full Name</span>
                                                            <i className="fa fa-user input-icon-outside"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="position-relative mt-4">
                                                            <input type="text" className="outside" onChange={(e) => setRegisterUserName(e.target.value)} value={registerUserName} />
                                                            <span className="floating-label-outside">username</span>
                                                            <i className="fa fa-user input-icon-outside"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="position-relative mt-4">
                                                            <input type="text" className="outside" onChange={(e) => setEmail(e.target.value)} value={email} />
                                                            <span className="floating-label-outside">Email</span>
                                                            <i className="fa fa-envelope input-icon-outside"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="position-relative mt-4">
                                                            <input type="text" className="outside" onChange={(e) => setMobile(e.target.value)} value={mobile} />
                                                            <span className="floating-label-outside">Mobile Number</span>
                                                            <i className="fa fa-mobile input-icon-outside"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="position-relative mt-4">
                                                            <input type="text" className="outside" onChange={(e) => setLandline(e.target.value)} />
                                                            <span className="floating-label-outside">Ladline Number</span>
                                                            <i className="fa fa-phone input-icon-outside"></i>
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="position-relative mt-4">
                                                            <input type="text" className="outside" onChange={(e) => setApplicationName(e.target.value)} value={applicationName} />
                                                            <span className="floating-label-outside">Application Name</span>
                                                            <i className="fa fa-user input-icon-outside"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="position-relative mt-4">
                                                            <input type="text" className="outside" onChange={(e) => setapplicationDomain(e.target.value)} value={applicationDomain} />
                                                            <span className="floating-label-outside">Application Domain</span>
                                                            <i className="fa fa-user input-icon-outside"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="position-relative mt-4">
                                                            <input type="text" className="outside" onChange={(e) => setDepartmentName(e.target.value)} value={departmentName} />
                                                            <span className="floating-label-outside">Department Name</span>
                                                            <i className="fa fa-user input-icon-outside"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="position-relative mt-4">
                                                            <input type="text" className="outside" onChange={(e) => setUserPassword(e.target.value)} />
                                                            <span className="floating-label-outside">Password</span>
                                                            <i className="fa fa-key input-icon-outside"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                                        <div className="position-relative mt-4">
                                                            <input type="text" className="outside" onChange={checkPassword} />
                                                            <span className="floating-label-outside">Confirm Password</span>
                                                            <i className="fa fa-key input-icon-outside"></i>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="btn btn-block" id="signUpSubmitButton" onClick={submit} >Submit</button>
                                                    <div className="signup"> <p onClick={() => isLogin ? null : setIsLogin(true)}>Don't Have an Account?<span className="font-weight-600 ml-2">SignIn</span></p></div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

