import React from 'react';
export function Sidebar() {
    return (
        <>
            <div className="sidebar" id="sidebar">
                <div className="sidebar-inner slimscroll">
                    <div id="sidebar-menu" className="sidebar-menu">
                        <ul>
                            <li>
                                <a href="/home"><i className="fa fa-dashboard"></i> <span>Home</span></a>
                            </li>
                            <li>
                                <a href="/Shortlink"><i className="fa fa-link"></i> <span>Shorten Link</span></a>
                            </li>
                            <li>
                                <a href="/Unshortendurl"><i className="fa fa-unlink"></i> <span>Unshorten URL</span></a>
                            </li>
                        
                            <li>
                                <a href="/maliciousReport"><i className="fa fa-calendar-check-o"></i> <span>Report Malicious URL</span></a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>




        </>

    );
}