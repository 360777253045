import axios from "axios";
// import { data } from "jquery";
import getCodeVerifier from "../code_verifier";
import config from "../config";
import apiUrl from "./apiUrls";
import envJson from "../../env/index";
const { username, password, xApiKey } = envJson["envCredential"];
const authToken = localStorage.getItem("authToken") || "";

export function parichayLogIn() {
  const verifier = getCodeVerifier();
  const code_challenge = verifier.challenge;
  const url = new URL(config.parichay.base_url);
  url.pathname = config.parichay.path;
  let params = {
    response_type: config.parichay.response_type,
    client_id: config.parichay.client_id,
    redirect_uri: config.parichay.redirect_uri,
    scope: config.parichay.scope,
    code_challenge: code_challenge,
    code_challenge_method: config.parichay.code_challenge_method,
    state: config.parichay.state,
  };
  url.search = new URLSearchParams(params).toString();
  return [url.href, verifier.verifier];
}

export async function getParichayToken(objVerifier) {
  const currentUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(currentUrl.search);
  let code = searchParams.get("code");
  let params = {
    code: code,
    code_verifier: objVerifier,
  };
  const headers = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  let args = {
    body: params,
    headers: headers,
  };
  // return new Promise((resolve, reject) => {
  //   fetch(`http://52.66.205.189:8000/auth/v1/parichay/oauth2/token`, {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(params),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       resolve(data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       reject(error);
  //     });
  // });
  // return new Promise((resolve, reject) => {
  //   axios
  //     .post(apiUrl["parichayTokenApi"], args.body, { headers })
  //     .then((response) => {
  //       resolve(response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       reject(error);
  //     });
  // });
  //

  return axios
    .post(apiUrl["parichayUrlToken"], args.body)
    .then((response) => response)
    .catch((error) => error);
}

export async function getAccessToken(accessToken) {
  const headers = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  console.log(apiUrl["accessTokenUrl"]);
  const data = {
    username: username,
    password: password,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl["accessTokenUrl"], data, headers)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function getShortUrl(defaultUrl, accessToken) {
  // accessToken =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NTNiNDkwOGM0YWIyNTY5MzI4MzE3OGYiLCJyb2xlIjoiQVBJX0lOVEVHUkFUT1IiLCJzZXNzaW9uX2lkIjoiY2E3Njc0NzQtYzMzOS01Mzg4LWIzOTEtMGJkMDI0YmVjNjE3IiwiaWF0IjoxNjk4NTAwNDkyLCJleHAiOjE2OTg1MDIyOTJ9.MfLd-I88fthY_q4zDeSkwvuIi0aUbWzXi3xQJ49V3bU";

  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const data = {
    target_url: {
      default: defaultUrl,
    },
    generate_qr_code: true,
  };
  // return new Promise((resolve, reject) => {
  //   axios
  //     .post(apiUrl["shortenUrl"], data, config)
  //     .then((response) => {
  //       resolve(response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       reject(error);
  //     });
  // });

  console.log(authToken);

  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl["shortenUrl"], data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // console.log(error);
        return error;
      });
  });
}
