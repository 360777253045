import React, { useContext, useEffect } from "react";
import "./Homepage.css"
import { ClientContext } from "../../components/context/ClientContext"

import { Header } from "./Header";
import { Footer } from "./Footer"
import { Maincontent } from "./Content/Maincontent"
import { checkLocalUserSession } from "../../utils/api/userSessionApi";

export function Homepage() {
    const { customdata } = useContext(ClientContext)
    // const { checkUser } = customdata

    function checkSession() {
        console.log("checkLocalUserSession");
        if (customdata) {
            if (customdata.checkUser) {
                customdata.checkUser()
            }
        }
        // if (checkLocalUserSession()) {
        //     window.location.href = "/";
        //     return
        // }
    }

    useEffect(() => {
    })

    return (
        <>
            {checkSession()}
            <Header />
            <div className="main">
                <Maincontent />
            </div>

            <Footer />
        </>
    )
}