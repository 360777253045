import React, { useState, useEffect, useContext } from "react";

import { ClientContext } from "../../../components/context/ClientContext"

import { Navheader } from "../../Homepage/Header"
import linkllogo from "../../../assets/imges/logo-icon.svg"
import qrcodeimage from "../../../assets/imges/qrCodeImage.png"
import whatapp from "../../../assets/imges/WhatsApp.png";
import "./Unshortendurl.css"

export function Unshortendurl() {
    const { customData } = useContext(ClientContext)
    const [shortedUrlData, setShortedUrlData] = useState("")



    useEffect(() => {
        // getUrlDetailed()
    }, [])

    return (
        <>
            {/* <Navheader /> */}
            <div>
            <div className="container">
            <div className="mt-5 card text-center mx-auto pt-5">
            <h3 style={{
                "textAlign": "center",
                "color": "orange",
                "fontWeight": "bold"
            }}>Your Shortend URL</h3>
            <div className="underLineLog">
                <div style={{
                    "border": "1px solid",
                    "width": "20%",
                    "height": "0",
                    "borderColor": "orange",
                    "marginTop": "14px",
                    "position": "relative"
                }}></div>
                <img className="shortendlinkllogo" src={linkllogo} alt="notFound" />
                <div style={{
                    "border": "1px solid",
                    "width": "20%",
                    "height": "0",
                    "borderColor": "orange",
                    "marginTop": "15px",
                    "position": "relative"
                }}></div>

            </div>
            <p>Copy the shortend Link and Share it message text,whatsapp,website and other locations</p>
            <div className="row">
            <div className="col-lg-8 offset-4 mx-auto">
            <div class="input-group mb-4">  
            <input type="search" placeholder="http://www.example-long-abc123" aria-describedby="button-addon5" class="form-control" />  
            <div class="input-group-append">  
              <button id="button-addon5" type="submit" class="btn btn-warning text-white"> <i class="fa fa-copy"> </i> Copy URL </button>  
            </div>  
          </div>  
          <div className="col-lg-12 text-left p-0">
          <div className="d-flex"><h5 className="long-url">Long URL: </h5><span className="logurl-text"><a>http://www.example-long-link.com/shorten-it</a></span></div>
          </div>
          <div className="col-lg-12 no-padding">
          
          <div className="row no-padding">
          <div className="col-lg-8 p-0 m-0">
          
          <img className="whatsappimg" src= {qrcodeimage} />
          
          <h4 className="scan-title p-0 font-weight-600"> Scan the QR Code to get the Short URL</h4>
          </div>
          
          </div> 
          <div className="col-lg-12">
          <div className="share-section">
          <h5> Share Shortent URL:</h5> <img className="whatsaap-img" src={whatapp}></img>
          </div>
          
          </div>
          </div>
            </div>
           
            </div>
            

        </div>
            </div>
           
            {/* <Footerscreen /> */}
        </div>

        </>
    )
}

