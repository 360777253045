import React from "react";
import "./Loader.css"

export default function Loader({ showLoader }) {
    // console.log(showLoader);
    if (showLoader) {
        return (
            <div className="loader"></div>
        )
    }
    else {
        return
    }
}

