import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClientContext } from "../../../components/context/ClientContext"
import { Navheader } from "../../Homepage/Header"
import "./shortLink.css"
import s from './shortLink.module.scss'
import { getAccessToken, getParichayToken, getShortUrl } from "../../../utils/api/index.js";

export function Shortlink() {
    const navigate = useNavigate();
    const { customData, setCustomData } = useContext(ClientContext)
    const [defaultUrl, setDefaultUrl] = useState('')

    async function getAccesToken() {
        const response = await getAccessToken(localStorage.getItem('codeVerifier'))
        if (response.status === 200 && response.data.auth_token) {
            setCustomData({ ...customData, auth_token: response.data.auth_token })
        }
    }

    async function getSortUrl() {
        const response = await getShortUrl(defaultUrl, localStorage.getItem('authToken'))
        console.log(response);
        if (response.status === 200) {
            setCustomData({ ...customData, defaultUrl: defaultUrl, shortUrlData: response.data })
            navigate('/Shortendurl')
        }

    }

    useEffect(() => {
      
    }, [])

    return (
        <>
            {/* <Navheader /> */}
            <section className="main">
            <div className="container">
                <div className="row mb-5 mt-5">
                    <div className="col-lg-11 mx-auto">
                        <div className="card light-bg">

                            <div className="card-body mb-5">
                                <div className="card-info-text">
                                    <div><span>Contrary</span> to popular belief</div>

                                </div>
                                <div className="mb-5 mt-4"><p className="text-center">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old</p></div>

                                <div className="search-bg p-5">
                                    <form action="" method="post">
                                        <div className="form-group mb-0">
                                            <label className="search-heading">Long URL/ Target URL</label>
                                            <div className="input-group mb-1">
                                                <input type="url" placeholder="Example: http://example-long-link.com/shorten-it" onChange={(e) => setDefaultUrl(e.target.value)} aria-describedby="button-addon5" className="form-control" />
                                                <div className="input-group-append">
                                                    <button id="button-addon5" type="button" onClick={getSortUrl} className="btn btn-login"> <i className="fa fa-search"> </i> Shorten URL </button>
                                                </div>
                                            </div>
                                            <div className="advance-search-heading"><p><span><Link to="/Useradvance">Advance Option</Link></span></p></div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <h2>{defaultUrl}</h2>
                    </div>
                </div>
            </div>
        </section>

        </>


    )
}
