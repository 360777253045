import React from "react";
import { Link } from "react-router-dom";
import "./Maincontent.css"
// import iconFeature from "../../../"
// import powerLinkImage from "../../../assets/imges/powerLinkImage.png"
import powerLinkIcon from "../../../assets/imges/powerLinkRight.png"
import FaqIcon from "../../../assets/imges/Faq.png"


// import iconn2 from "../../../assets/imges/hero-bg1.png"

export function Maincontent() {

    return (
        <>
            <Shorturlsearch />
            <FeaturesCard />
            <ShortlinkPower />
            <FaqView />
        </>
    )
}

function Shorturlsearch() {
    return (<>
        <div className="container">
            <div className="searchDiv">
                <h3 className="short-heading" id="shortUrlHeader">Short URL</h3>
                <div id="searchDivContainer">
                    <label className="longUrl">Long URL / Target URL</label>
                    <div className="form-group" id="urlInputDiv">
                        <input type="url" className="form-control" id="searchFormInput" placeholder="Example: http://example-long-link.com/shorten-it" />
                        <Link to={`/login`} type="button" className="btn btn-md btn-warning text-white mb-2" id="signUpYourLink">SignUp and Get Your Link</Link>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

function FeaturesCard() {
    const featurCardArr = [{
        "title": "Swift and Seamless",
        "image": require("../../../assets/imges/featureImage/swiftSeamles.png"),
        "para": "Enter your lengthy link, and within moments, enjoy a short and more convenient URL ready to be shared effortlessly. With URL, it's a breeze."
    }, {
        "title": "Shielding Your Links",
        "image": require("../../../assets/imges/featureImage/Links.png"),
        "para": "Your Links, Your Data, Our Security. Experience the confidence of a secure URL shortener, fortified with robust security measures."
    }, {
        "title": "Comprehensive Statistics",
        "image": require("../../../assets/imges/featureImage/reliableClicks.png"),
        "para": "Track the number of clicks and gain valuable insights like devices and operating systems used to access your shortened URLs."
    }, {
        "title": "Reliable Clicks",
        "image": require("../../../assets/imges/featureImage/comprehnsiv.png"),
        "para": "Count on us to safeguard you from malicious domains, providing robust protection against phishing attacks, spam, and malware threats."
    }, {
        "title": "Seamless API Integration",
        "image": require("../../../assets/imges/featureImage/Integration.png"),
        "para": "Integrate our service seamlessly into your workflow with our API. Create short links tailored to your specific needs with ease."
    }, {
        "title": "Responsive",
        "image": require("../../../assets/imges/featureImage/Responsiv.png"),
        "para": "Completely strategize client-centric niche markets for go forward Phosfluorescently iterate efficient internal."
    }
    ]
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mt-5 mb-3">
                            <h3 style={{ "textAlign": "center", "fontWeight": "bold" }}>Features</h3>
                        </div>
                    </div>
                    <div className="row">

                        {featurCardArr.map((obj) =>
                            <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={obj.title}>
                                <div className="card img-box">
                                    <div key={obj.title}>
                                        <div className="card-content">
                                            <img className="img"
                                                src={obj.image}
                                                alt="Card imagecap" />

                                            <h5 className="card-title" style={{ "fontWeight": "bold" }}>{obj.title} </h5>
                                            <p className="card-text" style={{ "fontSize": ".8rem" }}>{obj.para}</p>
                                        </div>

                                        <p className="breakLine"></p>
                                    </div>

                                </div>

                            </div>
                        )}
                    </div>

                </div>
            </section>
        </>

    )
}

function ShortlinkPower() {
    const arr = [{
        "title": "Custom Short URLs",
        "image": require("../../../assets/imges/shortLinkPower/custom-link.png"),
        "para": "Craft unique, memorable links like \"https://url.in/RegisterForPMJAY\" for personalized approach."
    }, {
        "title": "Adaptive Target URLs",
        "image": require("../../../assets/imges/shortLinkPower/adaptive.png"),
        "para": "Automatically direct users to the right destination based on their device—Android, iOS, and more."
    }, {
        "title": "QR Code Creation",
        "image": require("../../../assets/imges/shortLinkPower/QR-code.png"),
        "para": "Instantly generate QR codes for easy sharing across all devices.Simply scan, and your short link opens on any platform."
    }, {
        "title": "Custom Logo QR Codes",
        "image": require("../../../assets/imges/shortLinkPower/custom-logo.png"),
        "para": "Take charge with advanced controls.Set limits on clicks, choose link expiry times, and even implement geo - fencing for added security."
    }, {
        "title": " Control URL Access",
        "image": require("../../../assets/imges/shortLinkPower/control-url.png"),
        "para": "Craft unique, memorable links like \"https://url.in/RegisterForPMJAY\" for personalized approach."
    }, {
        "title": "Password Protection",
        "image": require("../../../assets/imges/shortLinkPower/password-protection.png"),
        "para": "Keep your links secure.Only those with the password can access the target URL, ensuring privacy and exclusivity."
    },
    ]
    return (
        <>
            <section className="bg-custom mt-5 pb-120 pt-120">
                <div className="container">
                    <div className="row">

                        <div className="col-md-7 col-lg-7" >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="unleash-the-power-title1">
                                        <h1>Unleash The Power Of Short Links</h1>
                                    </div>
                                    <p style={{ "fontSize": "16px" }}>
                                        Discover a world of possibilities with our URL shortener service, packed with remarkable features to
                                        simplify your link-sharing experience:
                                    </p>
                                </div>
                            </div>
                            {
                                arr.map((obj) =>
                                    <div className="custom_short_content pb-3" key={obj.title}>
                                        <div className="custom_short_icon">
                                            <div className="icon">
                                                <img
                                                    className="card-img-top"
                                                    src={obj.image}
                                                    alt="" />
                                            </div>
                                        </div>
                                        <div className="custom_short_content_inner">
                                            <h4>{obj.title}</h4>
                                            <p> {obj.para}</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="col-md-5 col-lg-5">
                            <div className="unleash-the-power-thumb wow fadeInRight animate">
                                <div className="unleash-the-power-thumb2">
                                    <img
                                        className="unless-img" src={powerLinkIcon} alt="" />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="shorturl_section">
                <div className="shorturl_section-content">
                    <div className="shorturl_section-title text-center">
                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                                <h1>Short URLs That You Can Trust Upon</h1>
                                <button type="button" className="btn btn-warning mb-2" id="signUpYourLink">SignUp and Get Your Link
                                    &nbsp; <span>&#8594;</span>
                                </button>
                            </div>

                        </div>
                    </div>

                </div>

            </section>

        </>
    )
}


function FaqView() {

    const arr = [{
        "quetion": "What is a URL shortener ?",
        "answer": "A URL shortener is a web tool that takes long, complex web addresses and transforms them into shorter, more manageable links.These shortened URLs are easier to share, type, and remember."
    }, {
        "quetion": "What are the benefits of a short URL ?",
        "answer": "Short URLs are not only convenient but also offer several advantages.They improve link aesthetics, enhance user experience, and make sharing effortless.Short URLs are particularly valuable on social media and in print, where character limits and space constraints matter."
    }, {
        "quetion": "What is a custom URL shortener ?",
        "answer": " A custom URL shortener allows you to create personalized, branded short links.You can craft links that align with your brand or message, making them memorable and instantly recognizable."
    }, {
        "quetion": "What is a QR Code ?",
        "answer": "A QR Code, or Quick Response Code, is a two - dimensional barcode that stores information.Scanning a QR Code with a smartphone or camera app quickly retrieves the encoded data, which could be a URL, text, contact information, or more."
    }, {
        "quetion": "What can a QR Code do?",
        "answer": "QR Codes can perform various functions, such as opening a web page, displaying contact information, adding calendar events, providing Wi - Fi credentials, and much more.Their versatility makes them a powerful tool for information sharing and engagement."
    }, {
        "quetion": "Why choose URL.in service ? ",
        "answer": "URL.in offers more than just link shortening.We provide a range of features, including custom short URLs, adaptive target destinations, secure QR code generation, access control, password protection, and seamless API integration.Choose us to simplify your link - sharing experience and enhance your online security."
    }
    ]
    function faqQua(e) {
        let event = e.target
        let faqqq = document.querySelectorAll('.faqAnswer')
        var panel = event.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            for (let i = faqqq.length - 1; i >= 0; i--) {
                faqqq[i].style.maxHeight = null;
            }
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    }

    return (
        <>
            <section>
                <div className="faq">
                    <h3 className="mt-4 mb-4 font-weight-700">Frequently Asked Quetions</h3>
                    <div className="row">
                        <div className="col-md-5 col-lg-5 col-sm-12">
                            <img src={FaqIcon} alt="Card imagecap" />
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12">
                            <div className="container">
                                {
                                    arr.map((obj) =>
                                        <div className="faqCard" key={obj.quetion}>
                                            <div className="Faq-container">
                                                <h3 onClick={faqQua} className="FaqQuetion">{obj.quetion}</h3>
                                                <div className="faqAnswer">
                                                    <p> {obj.answer}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}