import React from "react";

import { Footerscreen } from "../Homepage/Footer.jsx"
import { Navheader } from "../Homepage/Header"
import "./Notfound.css"
import notFound from "../../assets/imges/404-img.svg"


// Screenshot 2023-10-04 at 11.20.17 AM.png

// import "../Homepage/Footer.css"


export function Notfound() {

    return (
        <>
            {/* <Navheader /> */}
            <div className="notFound">
                <div className="row" id="oopsMsg">
                    <div className="col-lg-12 mt-12 mb-12">
                        <img className="notFoundImage" src={notFound} alt="notFound" />
                    </div>
                    <div className="col-lg-12 mt-12 mb-12">
                        <h3>Opps!</h3>
                        <p>The Page You are Looking for does not Exist </p>
                    </div>
                </div>
                <button type="button" className="btn btn-md btn-warning mb-2" id="backHome">Back To Home</button>

            </div>
            {/* <Footerscreen /> */}


        </>
    )
}

