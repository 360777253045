import cryptoJS from "crypto-js";
import randomByte from "randombytes";
function base64URLEncode(str) {
  const base64Encode = str
    .toString(cryptoJS.enc.Base64)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
  return base64Encode;
}

function verifierGen(str) {
  return str.toString("base64");
}

function sha256(buffer) {
  return cryptoJS.SHA256(buffer);
}

function getCodeVerifier() {
  //   const randomBytes = cryptoJS.lib.WordArray.random(32);
  const verifier = verifierGen(randomByte(32));
  const challenge = base64URLEncode(sha256(verifier));
  return {
    verifier: verifier,
    challenge: challenge,
  };
}

export default getCodeVerifier;
