
import { useState, useEffect, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Link, useNavigate } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import history from "../../utils/History/history"  //"./utils/History/history";
import { ClientContext } from "../../components/context/ClientContext"
import WOW from 'wowjs';

import $ from 'jquery';
import "./Header.css"
import { parichayLogIn } from '../../utils/api'

import { checkLocalUserSession, userlogOut } from "../../utils/api/userSessionApi"

import logo from "../../assets/imges/logo.png";
import key from "../../assets/imges/key.svg";
import right from "../../assets/imges/right-bg.svg";
import apiinteg from "../../assets/imges/api-integtrator.svg";
import { Login } from "../UrlAuthentication/Login";
import { Component } from "react";

export function Navheader(props) {
    const { customData, setCustomData } = useContext(ClientContext)
    const navigate = useNavigate()
    let userDetail = {}

    const [parichayUrl, setparichayUrl] = useState('')
    let isUserLogIn = false

    if (customData) {
        if (customData.hasOwnProperty("userDetail")) {
            userDetail = customData.userDetail
            isUserLogIn = true

        }
        // console.log(customData);
    }

    const getUrlVerfier = async () => {
        const urlObj = parichayLogIn()
        console.log(urlObj)
        localStorage.setItem('codeVerifier', urlObj ? urlObj[1] : null);
        setparichayUrl(urlObj ? urlObj[0] : null)
    }

    async function urlParichayToken() {
        if (!localStorage.getItem("authToken")) {
            // navigate("/home")
        }
    }
    async function userLogOut() {
        const response = await userlogOut(localStorage.getItem('authToken'))
        if (response.status === 200) {
            localStorage.removeItem('authToken');
            // window.location.href = ("/home")
            window.location.replace("/home")
        }
    }

    useEffect(() => {
        urlParichayToken()
        getUrlVerfier()
    }, [])
    return (<>
        <div id="sticky-header" className="nav_custom techno_nav_manu transparent_menu white d-md-none d-lg-block d-sm-none d-none style-two">
            <div className="container">
                <div className="row">
                    <div className="menu">
                        <a href="/home" className="logo">
                            <img className="down" src={logo} alt="URLin" />
                            <img className="main_sticky" src={logo} alt="logo"></img>
                        </a>
                        <a className="menu-mobile">&nbsp;</a>
                        <ul className="clearfix">
                            {
                                isUserLogIn
                                    ? <>
                                    </> : null
                            }
                            <li><a href='/home'>Dashboard</a></li>
                            <li><a href='/'>Shorten Link</a></li>
                            <li><a href='/Unshortendurl' >Unshorten URL</a></li>
                            <li><a href={'/maliciousReport'}>Report Malicious URL</a></li>
                            <div className="donate-btn-header">
                                <div id="login_btn" className="navbar-nav ml-auto">
                                    <details className="dropdown">
                                        <summary role="button" id="showim">
                                            {isUserLogIn ?
                                                <>
                                                    <span className="button">{userDetail.full_name}<svg width="4.7" height="8" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg" className="login-icon">
                                                        <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" fill="#fff" className="_2gTTdy"></path>
                                                    </svg></span>

                                                    <ul id="showme">
                                                        <li><Link onClick={userLogOut} ><i className="fa fa-shutdown"></i> Log Out </Link></li>
                                                    </ul>
                                                </>
                                                :
                                                <>
                                                    <span className="button">Login<svg width="4.7" height="8" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg" className="login-icon">
                                                        <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" fill="#fff" className="_2gTTdy"></path>
                                                    </svg></span>
                                                    <ul id="showme">
                                                        <li><Link to={parichayUrl}> <i className="fa fa-user-o"></i > Parichay Users</Link></li>
                                                        <li><Link to={"/login"}><i className="fa fa-code"></i>API Integrator</Link></li>
                                                    </ul>
                                                </>
                                            }
                                            {/* ///// */}
                                        </summary>
                                    </details>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export function Header() {
    const { customData, setCustomData } = useContext(ClientContext)
    const [redirectUrl, serRediretUrl] = useState('')

    var wind = $(window);
    window.onscroll = function () {
        setHeaderOnTop()
    };
    function setHeaderOnTop() {
        var scroll = wind.scrollTop();
        let sticky = document.getElementById("sticky-header");
        if (sticky) {
            if (scroll <= 100) {
                sticky.classList.remove("sticky");
            } else {
                sticky.classList.add("sticky")
            }
        }
    }

    function pushLogin() {
        setCustomData({ ...customData, isLogIn: true })
    }

    const getUrlVerfier = async () => {
       
        const urlObj = await parichayLogIn()
        serRediretUrl(urlObj ? urlObj[0] : null)
        localStorage.setItem('codeVerifier', urlObj ? urlObj[1] : "");
        if (customData.setHeaderFooter) {
            const setHeaderFooter = customData.setHeaderFooter
            setHeaderFooter(false)
        }
        new WOW.WOW({
            live: false
        }).init();
    }
    useEffect(() => {
        getUrlVerfier()
    }, [])

    return (
        <>
            <div id="sticky-header" className="techno_nav_manu transparent_menu white d-md-none d-lg-block d-sm-none d-none style-two">
                <div className="container">
                    <div className="row">
                        <div className="menu">
                            <a className="logo" href={"/logo"}>
                                <img className="down" src={logo} alt="URLin" />
                                <img className="main_sticky" src={logo} alt="logo"></img>
                            </a>
                            <a href={"/"} className="menu-mobile">&nbsp;</a>
                            <ul className="clearfix">
                                <li><a href={"/"} >Shorten Link</a></li>
                                <li><a href={"/"}>Unshorten URL</a></li>
                                <li><a href={"/"}>Report Malicious URL</a></li>
                                <li><a href={"/"}>Contact</a></li>
                                <div className="donate-btn-header">
                                    <div className="navbar-nav ml-auto">
                                        <details className="dropdown">
                                            <summary role="button" id="showim">
                                                <span className="button">Login<svg width="4.7" height="8" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg" className="login-icon">
                                                    <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" fill="#fff" className="_2gTTdy"></path>
                                                </svg></span>
                                                <ul id="showme">
                                                    <li><Link to={redirectUrl}> <i className="fa fa-user-o"></i > Parichay Users</Link></li>
                                                    <li><Link to={"/login"}><i className="fa fa-code"></i>API Integrator</Link></li>
                                                </ul>
                                                {/* <span className="button" onClick={() => console.log(customData)}>Login</span>
                                                <ul id="showme">
                                                    <li><a href={redirectUrl}><img className="key-icon" src={key} alt="" /> Parichay Users</a></li>
                                                    <li><Link to={"/login"} onClick={pushLogin}><img className="key-icon-Integrat" src={apiinteg} alt="" />API Integrator</Link></li>
                                                </ul> */}
                                            </summary>
                                        </details>
                                    </div>
                                </div>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
            <div className="slider_area d-flex align-items-center slider-bg" id="home">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-7 col-lg-7">
                            <div className="single_slider wow fadeInUp animated">
                                <div className="slider_content">
                                    <div className="slider_text">
                                        <div className="slider_text_inner">
                                            <div className="slider_text_inner">
                                                <h1>Create Short Link, QR Codes</h1>
                                                <h1>Share them anywhere</h1>
                                                <h1>Track your link Clicks</h1>
                                                <p>Transforming Long URLs into Quick Clicks!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-5">
                            <div className="hero_section_thumb wow fadeInDown animated" style={{ "left": 0 }}>
                                <div className="hero-main-thumb">
                                    <img className="" src={right} alt="right" title="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>


    )
}
