import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Footer } from "../Homepage/Footer.jsx"
import { Navheader } from "../Homepage/Header"
import "./Linkexpire.css"
import linkExpire from "../../assets/imges/link-expire.svg"



export function Linkexpire() {
    return (
        <>
            <Navheader />
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100">
                        <div className="login100-form">
                            <div className="tex-center">
                                <div className="link-expire-img">
                                    {<img height={300} src={linkExpire} alt="notFound" />}
                                </div>

                                <h4 className="login100-form-title ">Link You Followed has Expire!</h4>
                                <button type="button" className="login100-form-btn" id="backHome">Back To Home</button>

                            </div>
                        </div>

                    </div>
                </div>


            </div>


            <Footer />
        </>
    )
}


/* Login Function */

// export function LoginPage() {
//     return (
//         <>
//             <Navheader />

//             <section className="ftco-section">
//                 <div className="container">

//                     <div className="row justify-content-center">
//                         <div className="col-md-12 col-lg-10">
//                             <div className="wrap d-md-flex">
//                                 <div className="img">
//                                     <img className="logo-login" src={logo} alt="" />
//                                     <div className="login-title">
//                                         <h3>Welcome to URL.in !</h3>
//                                     </div>
//                                 </div>

//                                 <div className="login-wrap p-4 p-md-0">
//                                     <div className="form-logo">
//                                         <img className="linkllogo" src={linkllogo} alt="notFound" />
//                                     </div>
//                                     <div className="d-flex"><h3 className="login-form-title">Login</h3></div>
//                                     <div className="signin-form">
//                                         <form>
//                                             <div className="form-group has-search" id="imageInputGroup">
//                                                 <img className="userSvg" src={userSvg} alt="notFound" />
//                                                 <input type="text" className="form-control" id="userInput" placeholder="Username" />
//                                             </div>
//                                             <div className="form-group has-search mb-0" id="imageInputGroup">
//                                                 <img className="passworlogo" src={passworlogo} alt="notFound" />
//                                                 <input type="text" className="form-control" id="userInput" placeholder="Password" />
//                                             </div>
//                                             <div className="forget-password"> <p>Forget your password?</p></div>
//                                             <div className="form-group">
//                                                 <input type="text" className="form-control" placeholder="Enter Captcha" />
//                                             </div>
//                                             <div className="form-group">
//                                                 <button type="button" className="btn btn-block" id="loginButton">Sign in</button>
//                                             </div>
//                                             <div className="signup"> <p>Don't Have an Account?<span className="font-weight-600 ml-2">SignUp</span></p></div>
//                                         </form>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>



           
//             <Footerscreen />


//         </>
//     )


// }