let keys = {
  parichay: {
    base_url: "https://parichay.staging.nic.in",
    path: "/pnv1/oauth2/authorize",
    response_type: "code",
    client_id: "X0hEDIFMVeAaQjV2t0etudaq34cyd781",
    redirect_uri: "http://52.66.134.198:80",
    scope: "user_details",
    code_challenge_method: "SHA256",
    state: "Centre",
  },
  serviceCredential: {
    serviceId: "X0hEDIFMVeAaQjV2t0etudaq34cyd781",
    clientSecret: "dflXWyLaLM9eFzH7PHkAvrydlvh5682T",
    authKey: "PLdd23GlnktylnielP7LnNZBRZySN7yt",
  },
};
module.exports = keys;

