import axios from "axios";
import getCodeVerifier from "../code_verifier";
import config from "../config";
import apiUrl from "./apiUrls";
import envJson from "../../env/index";
const { username, password, xApiKey } = envJson["envCredential"];
const authToken = localStorage.getItem("authToken") || "";

export function parichayLogIn() {
  const verifier = getCodeVerifier();
  const code_challenge = verifier.challenge;
  const url = new URL(config.parichay.base_url);
  url.pathname = config.parichay.path;
  let params = {
    response_type: config.parichay.response_type,
    client_id: config.parichay.client_id,
    redirect_uri: config.parichay.redirect_uri,
    scope: config.parichay.scope,
    code_challenge: code_challenge,
    code_challenge_method: config.parichay.code_challenge_method,
    state: config.parichay.state,
  };
  url.search = new URLSearchParams(params).toString();
  return [url.href, verifier.verifier];
}

export async function getParichayToken(objVerifier) {
  const currentUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(currentUrl.search);
  let code = searchParams.get("code");
  let params = {
    code: code,
    code_verifier: objVerifier,
  };
  const headers = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  let args = {
    body: params,
    headers: headers,
  };
  return axios
    .post(apiUrl["parichayUrlToken"], args.body)
    .then((response) => response)
    .catch((error) => error);
}

export async function getUserDetail(accessToken) {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  console.log(config);
  return axios
    .post(apiUrl["userDetail"], {}, config)
    .then((response) => response)
    .catch((error) => error);
}

export async function userRegistration(obj) {
  return axios
    .post(apiUrl["registerationUrl"], obj)
    .then((response) => response)
    .catch((error) => error);
}

export async function getLoginCaptcha() {
  const config = {
    headers: {
      "x-api-key": xApiKey,
    },
  };
  return axios
    .post(apiUrl["generateLoginCapctha"], {}, config)
    .then((response) => response)
    .catch((error) => error);
}

export async function getValidateCaptcha(data) {
  const config = {
    headers: {
      "x-api-key": xApiKey,
    },
  };
  return axios
    .post(apiUrl["vailidaCaptchaUrl"], data, config)
    .then((response) => response)
    .catch((error) => error);
}

export async function userlogOut(accessToken = authToken) {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  console.log(config);
  return axios
    .post(apiUrl["userLogOutUrl"], {}, config)
    .then((response) => response)
    .catch((error) => error);
}

export async function submitUserLogin(obj) {
  const headers = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  console.log(config);
  return axios
    .post(apiUrl["userLoginUrl"], obj, headers)
    .then((response) => response)
    .catch((error) => error);
}

export function checkLocalUserSession() {
  const authToken = localStorage.getItem("authToken") || "";
  return authToken ? true : false;
}
