import React, { useState } from "react";
import "./Useradvanceform.css";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';





export function Useradvanceform() {

    const [startDate, dateValue, onDateChange] = useState(new Date());

    return (

        <>
            <div className="container">
                <div className="row mt-5 mb-5">
                    <div className="col-lg-8 offset-lg-4 mx-md-auto">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title p-0 m-0">Advance Form</h4>
                            </div>
                            <div className="card-body">
                                <form id="advanceForm">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12"><h5 className="card-title py-2 px-2 bg-light">Target URL</h5></div>
                                        <div className="col-lg-12 col-md-6 col-sm-12">
                                            <div className="position-relative mt-4">

                                                <input type="text" className="outside" required />
                                                <span className="floating-label-outside">Default</span>
                                                <i className="fa fa-link input-icon-outside"></i>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6 col-sm-12">
                                            <div className="position-relative mt-4">
                                                <input type="text" className="outside" required />
                                                <span className="floating-label-outside">Windows</span>
                                                <i className="fa fa-windows input-icon-outside"></i>
                                            </div>

                                        </div>
                                        <div className="col-lg-12 col-md-6 col-sm-12">
                                            <div className="position-relative mt-4">
                                                <input type="text" className="outside" required />
                                                <span className="floating-label-outside">Laptop</span>
                                                <i className="fa fa-laptop input-icon-outside"></i>
                                            </div>


                                        </div>
                                        <div className="col-lg-12 col-md-6 col-sm-12">
                                            <div className="position-relative mt-4">
                                                <input type="text" className="outside" required />
                                                <span className="floating-label-outside">Linux</span>
                                                <i className="fa fa-linux input-icon-outside"></i>
                                            </div>


                                        </div>
                                        <div className="col-lg-12 col-md-6 col-sm-12">
                                            <div className="position-relative mt-4">
                                                <input type="text" className="outside" required />
                                                <span className="floating-label-outside">Linux</span>
                                                <i className="fa fa-android input-icon-outside"></i>
                                            </div>

                                        </div>
                                        <div className="col-lg-12 col-md-6 col-sm-12">
                                            <div className="position-relative mt-4">
                                                <input type="text" className="outside" required />
                                                <span className="floating-label-outside">Apple</span>
                                                <i className="fa fa-apple input-icon-outside"></i>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <h5 className="card-title border-bottom py-1 px-1 bg-light">Customization</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 mt-2">
                                            <DatePicker showIcon selected={startDate}
                                                onChange={onDateChange}
                                                value={dateValue}
                                                autoFocus={false} showTimeInput
                                                className="date-picker"
                                                closeCalendar={false}
                                            />
                                            {/* <div className="position-relative mt-2">

                                                <input type="text" className="outside" required />
                                                <span className="floating-label-outside">Date</span>
                                                <i className="fa fa-calendar-o input-icon-outside"></i>
                                            </div> */}

                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12">

                                            <div className="position-relative mt-2">
                                                <input type="text" className="outside" required />
                                                <span className="floating-label-outside">Time</span>
                                                <i className="fa fa-clock-o input-icon-outside"></i>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                                            <div className="position-relative mt-4">
                                                <input type="text" className="outside" required />
                                                <span className="floating-label-outside">Custom short key</span>
                                                <i className="fa fa-key input-icon-outside"></i>
                                            </div>

                                            <span className="text-muted">(Only 7 characters)</span>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <div className="input-group mb-3">

                                                <button type="text" className="btn btn-warning text-white mt-4"><i className="fa fa-clock-o"></i> Check Availability</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-5 col-md-5 col-sm-12 mb-3">
                                            <div className="position-relative mt-4">
                                                <input type="text" className="outside" required />
                                                <span className="floating-label-outside">Allowed click count</span>
                                                <i className="fa fa-plus input-icon-outside"></i>
                                            </div>

                                            <span className="text-muted">(Enter only interger numbers)</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <h5 className=" card-title border-bottom py-1 px-1 bg-light">Access Restriction</h5>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch1" data-onstyle="success" />
                                                <label className="custom-control-label" htmlFor="customSwitch1">Allow for Public</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-sm-12 mb-3">
                                            <div className="position-relative mt-3">
                                                <input type="text" className="outside" required />
                                                <span className="floating-label-outside">Allowed IP</span>
                                                <i className="fa fa-server input-icon-outside"></i>
                                            </div>

                                            <span className="text-muted"> ( Separate comma )</span>
                                        </div>
                                    </div>

                                </form>

                            </div>
                            <div className="card-footer">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}