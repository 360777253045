import React from "react";
import "./MaliciousReport.css";



export function MaliciousReport() {

    return (
        <>
          <div className="container">
    <div className=" mt-5">
    <div className="row ">
    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
    <div className="card">
        <div className="card-header p-2">
            <h4 className="card-title d-inline-block">URL Click Count </h4> <a href="#" className="btn btn-sm btn-primary float-right">View all</a>
        </div>
        <div className="card-block">
            <div className="table-responsive">
                <table className="table mb-0 new-patient-table">
                    <thead>
                        <tr>
                            <th>Request ID </th>
                            <th>Short url </th>
                            <th>Create Date </th>
                            <th>Count </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>
                                <a href="#">google.com</a>
                            </td>
                            <td>09-11-2023</td>
                            <td>01</td>

                        </tr>
                        <tr>
                            <td>1</td>
                            <td>
                                <a href="#">google.com</a>
                            </td>
                            <td>09-11-2023</td>
                            <td>10</td>

                        </tr>
                        <tr>
                            <td>1</td>
                            <td>
                                <a href="#">google.com</a>
                            </td>
                            <td>09-11-2023</td>
                            <td>10</td>

                        </tr>


                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</div>
    </div>
          </div>
     
           

        </>
    )
}

