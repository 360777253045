import envJson from "../../env/index";
const { base_url } = envJson["envCredential"];

const apiUrl = {
  parichayTokenApi: `http://52.66.205.189:8000/auth/v1/parichay/oauth2/token`,
  parichayUrlToken: `${base_url}/auth/v1/parichay/oauth2/user/token`,
  userDetail: `${base_url}/user/v1/details`,
  generateLoginCapctha: `${base_url}/captcha/v1/generate`,
  vailidaCaptchaUrl: `${base_url}/captcha/v1/validate`,
  accessTokenUrl: `${base_url}/auth/v1/token`,
  shortenUrl: `${base_url}/url/v1/shorten`,
  registerationUrl: `${base_url}/user/v1/integrator/register`,
  userLoginUrl: `${base_url}/auth/v1/integrator/oauth2/user/token`,
  userLogOutUrl: `${base_url}/auth/v1/oauth2/user/logout`,
};

export default apiUrl;
