import React from 'react';
import Chart from 'react-apexcharts'


const series = [
    {
        name: "Users",
        data: [10, 20, 30, 45, 60, 30, 35, 18],
    },
];
const options = {
    chart: {
        type: "bar",
    },

    plotOptions: {
        bar: {
            columnWidth: '50%',
            distributed: true,
        },

    },
    xaxis: {
        categories: [
            "Desktop",
            "Laptop",
            "Mac",
            "tablet",
            "Mobile",
            "Android",
            "Ubntu",
            "Linux"
        ],

    },
};


function ApexBarChart() {

    return (
        <>
            <Chart options={options} type="bar" series={series} width="100%" />
        </>
    )

}
const data = {
    series: [
        {
            name: "Desktops",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
    ],
    options: {
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: true,
        },
        stroke: {
            curve: "straight",
        },
        title: {
            text: "Users By Device",
            align: "left",
        },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },
        legend: {
            show: true
        },


        xaxis: {
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
            ],
        },
    },
};
function ApexLineChart() {
    return (
        <>
            <Chart
                options={data.options}
                series={data.series}
                type="line"

            />
        </>
    )

}

function DounghtChart() {
    const series = [44, 55, 41];

    const options = {
        chart: {
            type: "donut",
        },
        labels: ['Male', 'Female', 'Other'],
        tooltip: {
            shared: true
        },

    };
    return (
        <>
            <Chart options={options} series={series} type="donut" height={300} />
        </>
    )
}

export function Content() {
    return (
        <>
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 className="page-title">Blank Page</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div className="dash-widget dash-widget-one1">
                                <span className="dash-widget-bg1"><i className="fa fa-link" aria-hidden="true"></i></span>
                                <div className="dash-widget-info text-right">
                                    <h3>98</h3>
                                    <span className="widget-title1">Total URL <i className="fa fa-check" aria-hidden="true"></i></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div className="dash-widget dash-widget-one2">
                                <span className="dash-widget-bg2"><i className="fa fa-hand-o-up"></i></span>
                                <div className="dash-widget-info text-right">
                                    <h3>1072</h3>
                                    <span className="widget-title2">Total Clicks <i className="fa fa-check" aria-hidden="true"></i></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div className="dash-widget dash-widget-one3">
                                <span className="dash-widget-bg3"><i className="fa fa-link" aria-hidden="true"></i></span>
                                <div className="dash-widget-info text-right">
                                    <h3>72</h3>
                                    <span className="widget-title3">Active URL <i className="fa fa-check" aria-hidden="true"></i></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div className="dash-widget dash-widget-one4">
                                <span className="dash-widget-bg4"><i className="fa fa-unlink" aria-hidden="true"></i></span>
                                <div className="dash-widget-info text-right">
                                    <h3>618</h3>
                                    <span className="widget-title4">Expire URL <i className="fa fa-check" aria-hidden="true"></i></span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12 col-md-7 col-lg-7 col-xl-7">
                            <div className="card">
                                <div className="card-header p-2">
                                    <h4 className="card-title d-inline-block">Active URL List </h4> <a href="#" className="btn btn-sm btn-primary float-right">View all</a>
                                </div>
                                <div className="card-block">
                                    <div className="table-responsive">
                                        <table className="table mb-0 new-patient-table">
                                            <thead>
                                                <tr>
                                                    <th>Request ID </th>
                                                    <th>Short url </th>
                                                    <th>Create Date </th>
                                                    <th>Expires at </th>
                                                    <th>Custom alias</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>
                                                        <a href="#">google.com</a>
                                                    </td>
                                                    <td>09-11-2023</td>
                                                    <td>09-11-2023</td>
                                                    <td>www.abc.com</td>
                                                    <td><button className="btn btn-primary btn-primary-two float-right">Active</button></td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>
                                                        <a href="#">google.com</a>
                                                    </td>
                                                    <td>09-11-2023</td>
                                                    <td>09-11-2023</td>
                                                    <td>www.abc.com</td>
                                                    <td><button className="btn btn-primary btn-primary-two float-right">Active</button></td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-5 col-xl-5">
                            <div className="card">
                                <div className="card-header p-2">
                                    <h4 className="card-title d-inline-block">URL Click Count </h4> <a href="#" className="btn btn-sm btn-primary float-right">View all</a>
                                </div>
                                <div className="card-block">
                                    <div className="table-responsive">
                                        <table className="table mb-0 new-patient-table">
                                            <thead>
                                                <tr>
                                                    <th>Request ID </th>
                                                    <th>Short url </th>
                                                    <th>Create Date </th>
                                                    <th>Count </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>
                                                        <a href="#">google.com</a>
                                                    </td>
                                                    <td>09-11-2023</td>
                                                    <td>01</td>

                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>
                                                        <a href="#">google.com</a>
                                                    </td>
                                                    <td>09-11-2023</td>
                                                    <td>10</td>

                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>
                                                        <a href="#">google.com</a>
                                                    </td>
                                                    <td>09-11-2023</td>
                                                    <td>10</td>

                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="card">
                                <div className="card-header p-2">
                                    <h4 className="card-title d-inline-block">Active URL List </h4> <a href="#" className="btn btn-sm btn-primary float-right">View all</a>
                                </div>
                                <div className="card-block">
                                    <ApexBarChart></ApexBarChart>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="card">
                                <div className="card-header p-2">
                                    <h4 className="card-title d-inline-block">Overall Statistics </h4> <a href="#" className="btn btn-sm btn-primary float-right">View all</a>
                                </div>
                                <div className="card-block">
                                    <ApexLineChart></ApexLineChart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="card">
                                <div className="card-header p-2">
                                    <h4 className="card-title d-inline-block">Users By Gender </h4> <a href="#" className="btn btn-sm btn-primary float-right">View all</a>
                                </div>
                                <div className="card-block">
                                    <DounghtChart></DounghtChart>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>








        </>

    );
}
