import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import history from "./utils/History/history";
import routes from "./Routes";
import routesComponents from "./Routes/routes";
import "font-awesome/css/font-awesome.min.css";
import "./App.css";
import { ClientContext } from "./components/context/ClientContext";
import Loader from "../src/commonComponents/Loader";
import { Homepage } from "./pages/Homepage/Homepage";
import { Shortendurl } from "./pages/shortendurl/Shortendurl";
import { Notfound } from "./pages/UrlAuthentication/Notfound.jsx";
import { Login } from "./pages/UrlAuthentication/Login.jsx";
import { Useradvanceform } from "./pages/UrlAuthentication/Useradvanceform/Useradvanceform.jsx";
import { Shortlink } from "./pages/shortendurl/shortLinks/Shortlink.jsx";
import { Unshortendurl } from "./pages/shortendurl/UnshortendUrls/Unshortendurl";
import { Navheader } from "./pages/Homepage/Header";
import { Footer } from "./pages/Homepage/Footer";
import { Layout } from "./Admin/Layout";
import { Linkexpire } from "./pages/UrlAuthentication/Linkexpire.jsx";
import {
  getUserDetail,
  checkLocalUserSession,
  getParichayToken,
} from "./utils/api/userSessionApi";

function App() {
  const [loadContext, setLoadContext] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [customData, setCustomData] = useState({});
  const [headerView, setHeaderView] = useState(false);

  async function checkUserSession() {
    const currentUrl = new URL(window.location.href);
    window.addEventListener("popstate", (event) => {
      // alert(
      //   `location: ${document.location}, state: ${JSON.stringify(event.state)}`
      // );
      // setLoadContext(false);
      if (checkLocalUserSession()) {
        // navigate("/")
        window.location.href = "/";
        // history.push("/");
        return;
      }
      console.log(currentUrl, new Date().toLocaleTimeString());
      window.location.href = "/home";
    });
    debugger;
    const authToken = localStorage.getItem("authToken");
    const searchParams = new URLSearchParams(currentUrl.search);
    const code = searchParams.get("code");
    let flag = true;
    for (const obj of routesComponents) {
      if (obj.path.toLowerCase() === currentUrl.pathname.toLowerCase()) {
        flag = false;
        break;
      }
    }

    if (flag) {
      window.location.href = "/";
      return;
    }
    if (authToken || code) {
      if (code) {
        await urlParichayToken();
      } else {
        const resp = await getUserDetail(authToken);
        if (resp.data) {
          const userDetail = {
            full_name: resp.data.contact_person
              ? resp.data.contact_person
              : resp.data.full_name,
            userDetail: resp.data,
          };
          setCustomData({
            ...customData,
            userDetail: userDetail,
            checkUser: () => {
              if (checkLocalUserSession()) {
                window.location.href = "/";
                return;
              }
            },
      hideFooter:()=> setHeaderView(false) 

          });
          history.push("/");
          if (currentUrl.pathname.toLowerCase() !== "/admin") {
            setHeaderView(true);
          }
          if (
            currentUrl.pathname.toLowerCase() !== "/" &&
            currentUrl.pathname.toLowerCase() !== "/shortendurl" &&
            currentUrl.pathname.toLowerCase() !== "/home" &&
            currentUrl.pathname.toLowerCase() !== "/login"
          ) {
            history.push(currentUrl.pathname);
          }
        } else {
          localStorage.removeItem("authToken");
          history.push("/home");
        }
      }
    } else {
      history.push("/home");
      setCustomData({
        ...setCustomData,
        setHeaderFooter: (obj) => setHeaderView(obj),
        checkUser: () => {
          if (checkLocalUserSession()) {
            window.location.href = "/";
            return;
          }
        },
      });
      localStorage.removeItem("authToken");
      if (currentUrl.pathname.toLowerCase() === "/login") {
        history.push(currentUrl.pathname);
        setHeaderView(true);
      }
    }
    setLoadContext(true);
  }

  async function urlParichayToken() {
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);
    const code = searchParams.get("code");
    if (currentUrl.pathname !== "/login" && code) {
      const response = await getParichayToken(
        localStorage.getItem("codeVerifier")
      );
      if (response.status === 200) {
        localStorage.setItem("authToken", response.data.auth_token);
        window.location.href = "/";
      } else {
        history.push("/404Notfound");
      }
    }
  }

  useEffect(() => {
    checkUserSession();
    console.log("AppJs Called");
  }, []);

  return (
    <>
      {
        loadContext ? (
          <ClientContext.Provider
            value={{
              customData,
              setCustomData,
              showLoader,
              setShowLoader,
            }}
          >
            <Router history={history}>
              {headerView ? <Navheader /> : ""}
              <div id="alliddleContentDiv">
                <Routes>
                  {routesComponents.map((obj) => (
                    <Route
                      exact
                      key={obj.key}
                      path={obj.path}
                      element={obj.compnents}
                    />
                  ))}
                </Routes>
              </div>
              {headerView ? <Footer /> : ""}
            </Router>
          </ClientContext.Provider>
        ) : (
          <Loader showLoader={true} />
        )
        // null
      }
    </>
  );
}

export default App;
