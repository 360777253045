import { useState, useEffect } from "react";

import "./Footer.css"
import logo from "../../assets/imges/logo.png"
import footerLogo from "../../assets/imges/digital-india.svg"


export function Footer() {
    const [showFooter, setShowFooter] = useState(false)

    function fixHeaderFooter() {
        const currentUrl = new URL(window.location.href);
        if (currentUrl.pathname.toLowerCase() !== "/home") {
            let footerDiv = document.getElementById("footer-wrap");
            const stickyHeader = document.getElementById("sticky-header").getBoundingClientRect().height;
            const middleContentHeight = document.getElementById("alliddleContentDiv").getBoundingClientRect().height;
            const num = footerDiv.getBoundingClientRect().height + stickyHeader + middleContentHeight;
            // console.log("window.innerHeight", window.innerHeight, "footerDiv.getBoundingClientRect().height", footerDiv.getBoundingClientRect().height, "stickyHeader", stickyHeader, "middleContentHeight", middleContentHeight);
            // console.log("NUMBER=========", num);
            footerDiv.style.marginTop = window.innerHeight > Number(num) ? Number(window.innerHeight - num) + "px" : null;
        }
    }
    window.onresize = function (event) {
        fixHeaderFooter()
    };

    useEffect(() => {
        const currentUrl = new URL(window.location.href);
        if (currentUrl.pathname.toLowerCase() !== "/home") {
            setShowFooter(true)
            fixHeaderFooter()
        }
    })
    return (
        <>
            <div id="footer-wrap" >
                {/* position=static */}
                <footer className="footer">
                    <div className="container">
                        {
                            !showFooter ?
                                <div id="top-footer" >
                                    <div className="row" id="topFootRow">
                                        <div className="col-md-3">
                                            <h4 className="widget-title pb-30">Navigation</h4>
                                            <div className="menu-quick-link-container ml-4">
                                                <ul id="menu-quick-link" className="menu">
                                                    <li><a href={"/ghfhgfgfg"}> Home </a></li>
                                                    <li><a href={"/ghfhgfgfg"}> Shorten Link </a></li>
                                                    <li><a href={"/ghfhgfgfg"}> Unshorten Link </a></li>
                                                    <li><a href={"/ghfhgfgfg"}> Report Malicious Url </a></li>
                                                    <li><a href={"/ghfhgfgfg"}> Term of service </a></li>
                                                    <li><a href={"/ghfhgfgfg"}> Privacy </a></li>
                                                    <li><a href={"/ghfhgfgfg"}> Contact </a></li>
                                                </ul>
                                            </div>

                                        </div>
                                        <div className="col-md-5" id="midDivFooter">
                                            <img className="down1" src={logo} alt="URL.in" />

                                            <p>
                                                At URL.in, we're dedicated to simplifying your online experience, one link at a time.
                                                Our innovative features, including custom short URLs, adaptive target destinations, and
                                                secure QR codes, empower you to share with confidence.Our commitment to your security is unwavering.
                                                With control over link access, password protection, and advanced API integration, we provide a secure platform for your online presence.
                                            </p>
                                        </div>
                                        <div className="col-md-4" id="endDivFooter">

                                        </div>

                                    </div>

                                </div>
                                :
                                null
                        }
                    </div>
                </footer>
                <div className="bottom-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="footer-left">
                                    <p className="copyright pt-3">
                                        Copyright &copy; 2023 All Right Reserved
                                    </p>
                                </div>
                                <div className="footer-right">
                                    <div> <img className="footer-digital-logo" src={footerLogo} alt="Digital India" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
}
