import { useContext, useEffect } from "react";
import { ClientContext } from "../../components/context/ClientContext"
import { Header } from './Header/Header';
import { Sidebar } from './Sidebar/Sidebar';
import { Content } from './Content/Content'
import { Footer } from './Footer/Footer';

export function Layout() {
    const { customData } = useContext(ClientContext)
    const {hideFooter}=customData
    function hideHeaderFooter(){
        
        console.log(customData );
        hideFooter()

    }

    useEffect(()=>
hideHeaderFooter()
    ,[])
    return (
        <>
            <div class="main-wrapper">
                <Header></Header>
                <div className="app-menu navbar-menu">
                    <Sidebar></Sidebar>
                </div>
                <div className="vertical-overlay"></div>
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <Content></Content>

                        </div>

                    </div>
                    <div class="sidebar-overlay" data-reff=""></div>
                </div>
                <footer></footer>
            </div>

        </>


    )
}